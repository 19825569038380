var render = function () {
  var _vm$$v$brokerData, _vm$$v$brokerData2, _vm$$v$brokerData3, _vm$$v$brokerData4, _vm$$v$brokerData5, _vm$$v$brokerData6, _vm$$v$brokerData7, _vm$$v$brokerData8, _vm$$v$brokerData9, _vm$$v$brokerData10;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "advisor-form"
  }, [_c('div', {
    staticClass: "advisor-form__actions"
  }, [_c('GenproxButton', {
    attrs: {
      "outline": true,
      "icon": _vm.ChevronLeftIcon,
      "arrowIconLeft": true
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Go back")])], 1), _c('div', {
    staticClass: "advisor-form__box"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    staticClass: "mb-5"
  }, [_vm._v(_vm._s(_vm.title))]), [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'E-MAIL',
      "disabled": _vm.viewType === _vm.AdvisorFormViewType.editAdvisor || !_vm.isEditable,
      "validation": _vm.$v.brokerData.email,
      "transform": "lowercase"
    },
    model: {
      value: _vm.brokerData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "email", $$v);
      },
      expression: "brokerData.email"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'FIRST NAME',
      "validation": _vm.$v.brokerData.firstName,
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.brokerData.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "firstName", $$v);
      },
      expression: "brokerData.firstName"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'SURNAME',
      "validation": _vm.$v.brokerData.surname,
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.brokerData.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "surname", $$v);
      },
      expression: "brokerData.surname"
    }
  }), _vm.displayField('identification_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'IDENTIFICATION NUMBER',
      "validation": (_vm$$v$brokerData = _vm.$v.brokerData) === null || _vm$$v$brokerData === void 0 ? void 0 : _vm$$v$brokerData.identificationNumber,
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.brokerData.identificationNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "identificationNumber", $$v);
      },
      expression: "brokerData.identificationNumber"
    }
  }) : _vm._e(), _vm.displayField('external_id') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'EXTERNAL ID',
      "mapNull": true,
      "validation": (_vm$$v$brokerData2 = _vm.$v.brokerData) === null || _vm$$v$brokerData2 === void 0 ? void 0 : _vm$$v$brokerData2.externalId,
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.brokerData.externalId,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "externalId", $$v);
      },
      expression: "brokerData.externalId"
    }
  }) : _vm._e(), _vm.displayField('phone_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'PHONE NUMBER',
      "validation": (_vm$$v$brokerData3 = _vm.$v.brokerData) === null || _vm$$v$brokerData3 === void 0 ? void 0 : _vm$$v$brokerData3.phoneNumber,
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.brokerData.phoneNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "phoneNumber", $$v);
      },
      expression: "brokerData.phoneNumber"
    }
  }) : _vm._e(), _vm.displayField('middle_name') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'MIDDLE NAME',
      "validation": (_vm$$v$brokerData4 = _vm.$v.brokerData) === null || _vm$$v$brokerData4 === void 0 ? void 0 : _vm$$v$brokerData4.middleName,
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.brokerData.middleName,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "middleName", $$v);
      },
      expression: "brokerData.middleName"
    }
  }) : _vm._e(), _vm.displayField('document_type') ? _c('sygni-select', {
    attrs: {
      "label": 'DOCUMENT TYPE',
      "options": _vm.documentTypes,
      "validation": (_vm$$v$brokerData5 = _vm.$v.brokerData) === null || _vm$$v$brokerData5 === void 0 ? void 0 : _vm$$v$brokerData5.documentType,
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.brokerData.documentType,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "documentType", $$v);
      },
      expression: "brokerData.documentType"
    }
  }) : _vm._e(), _vm.displayField('document_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'DOCUMENT NUMBER',
      "validation": (_vm$$v$brokerData6 = _vm.$v.brokerData) === null || _vm$$v$brokerData6 === void 0 ? void 0 : _vm$$v$brokerData6.documentNumber,
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.brokerData.documentNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "documentNumber", $$v);
      },
      expression: "brokerData.documentNumber"
    }
  }) : _vm._e(), _vm.displayField('document_expiry_date') ? _c('sygni-date-picker', {
    staticClass: "secondary",
    attrs: {
      "label": 'DOCUMENT EXPIRY DATE',
      "validation": (_vm$$v$brokerData7 = _vm.$v.brokerData) === null || _vm$$v$brokerData7 === void 0 ? void 0 : _vm$$v$brokerData7.documentExpiryDate,
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.brokerData.documentExpiryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "documentExpiryDate", $$v);
      },
      expression: "brokerData.documentExpiryDate"
    }
  }) : _vm._e(), _vm.displayField('document_country') ? _c('sygni-select', {
    attrs: {
      "label": 'DOCUMENT COUNTRY',
      "options": _vm.countries,
      "validation": (_vm$$v$brokerData8 = _vm.$v.brokerData) === null || _vm$$v$brokerData8 === void 0 ? void 0 : _vm$$v$brokerData8.documentCountry,
      "searchable": true,
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.brokerData.documentCountry,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "documentCountry", $$v);
      },
      expression: "brokerData.documentCountry"
    }
  }) : _vm._e(), _vm.displayField('start_power_of_attorney') ? _c('sygni-date-picker', {
    staticClass: "secondary",
    attrs: {
      "label": 'START POWER OF ATTORNEY',
      "validation": (_vm$$v$brokerData9 = _vm.$v.brokerData) === null || _vm$$v$brokerData9 === void 0 ? void 0 : _vm$$v$brokerData9.startPowerOfAttorney,
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.brokerData.startPowerOfAttorney,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "startPowerOfAttorney", $$v);
      },
      expression: "brokerData.startPowerOfAttorney"
    }
  }) : _vm._e(), _vm.displayField('end_power_of_attorney') ? _c('sygni-date-picker', {
    staticClass: "secondary",
    attrs: {
      "label": 'END POWER OF ATTORNEY',
      "validation": (_vm$$v$brokerData10 = _vm.$v.brokerData) === null || _vm$$v$brokerData10 === void 0 ? void 0 : _vm$$v$brokerData10.endPowerOfAttorney,
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.brokerData.endPowerOfAttorney,
      callback: function callback($$v) {
        _vm.$set(_vm.brokerData, "endPowerOfAttorney", $$v);
      },
      expression: "brokerData.endPowerOfAttorney"
    }
  }) : _vm._e()], _c('div', {
    staticClass: "table mt-1"
  }, [_vm._m(0), _c('div', {
    staticClass: "row mt-3"
  }, _vm._l(_vm.advisorPermissions, function (permission) {
    return _c('div', {
      key: permission.id,
      staticClass: "col-12 checkbox-col"
    }, [_c('sygni-checkbox', {
      staticClass: "secondary",
      attrs: {
        "value": _vm.hasPermission(permission),
        "label": permission.label,
        "disabled": !_vm.isEditable,
        "remove-watcher": true
      },
      on: {
        "changedValue": function changedValue($event) {
          return _vm.setPermission(permission);
        }
      }
    })], 1);
  }), 0)]), _vm.isEditable ? _c('GenproxButton', {
    staticClass: "mt-5",
    on: {
      "click": function click($event) {
        return _vm.goNext();
      }
    }
  }, [_vm._v(_vm._s(_vm.redButtonText) + " advisor")]) : _vm._e(), _c('div', {
    staticClass: "router-link",
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('div', {
    staticClass: "cancel-button mt-4"
  }, [_vm._v("Cancel")])])], 2)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mt-1"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "checkboxes-title"
  }, [_vm._v(" PERMISSIONS ")])])]);
}]

export { render, staticRenderFns }