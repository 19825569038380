


















































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import {
  InvestmentClient,
} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import {email, required} from "vuelidate/lib/validators";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {mapGetters, mapState} from "vuex";
import {Prop} from "vue-property-decorator";
import { AdvisorFormViewType } from "@/modules/genprox/modules/fund/modules/fundraising/modules/advisors/models/AdvisorFormViewType.enum";
import { AdvisorRole, AdvisorRoles } from "@/modules/genprox/modules/fund/modules/fundraising/modules/advisors/models/AdvisorRoles";
import { Broker } from '../store/types';
import { GlobalSelects, InvestorSelects } from '@/modules/shared/dictionary-store/types';
import GenproxButton from '@/components/buttons/GenproxButton.vue';
import ChevronLeftIcon from '@/assets/icons/heroicons/ChevronLeftIcon.vue';

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniInputError,
    SygniToggleSwitch,
    SygniRadio,
    ExpandCollapse,
    SygniRoundedButton, SygniRectButton, SygniTextArea, SygniDatePicker, SygniCheckbox, SygniLoader, SygniSelect, SygniInput, SygniContainerTitle, GenproxButton, ChevronLeftIcon },
  computed: {
    ...mapGetters('dictionary', {
      investorsSelects: 'investorsSelects',
      globalSelects: 'globalSelects',

    }),
    ...mapState('investors', {
      editInvestmentClientData: (state: any) => state.editInvestmentClientData,
    })
  }
})
export default class AdvisorsModule extends Vue {
  ChevronLeftIcon = ChevronLeftIcon;
  @Prop() investorId!: string;

  investorsSelects!: InvestorSelects;
  globalSelects!: GlobalSelects;

  brokerData: Broker = {
    firstName: '',
    surname: '',
    email: '',
    identificationNumber: null,
    externalId: null,
    phoneNumber: null,
    middleName: null,
    documentNumber: null,
    documentType: null,
    documentExpiryDate: null,
    documentCountry: null,
    startPowerOfAttorney: null,
    endPowerOfAttorney: null,
    permissions: [],
  }

  isLoading: boolean = false;
  editInvestmentClientData!: InvestmentClient;
  viewType: AdvisorFormViewType = AdvisorFormViewType.newAdvisor;
  roles: Array<boolean> = [];

  get advisorFormItems() {
    return this.$store.getters['advisors/advisorFormData']?.parameters?.items;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  get isEditable() {
    return !this.isMarketplaceAccountant
  }

  get capitalRiseLink() {
    return (this.$route.path.includes('company')) ? '/company/fundraising' : '/fund/fundraising';
  }

  displayField(name: string) {
    const field = this.advisorFormItems?.find((el: any) => el.name === name);

    return (field) ? true : false;
  }

  isAdvisorFormFieldRequired(name: string) {
    const field = this.advisorFormItems?.find((el: any) => el.name === name);

    return (field && field.required) ? true : false;
  }

  scrollToError(): void {
    const error: HTMLDivElement = this.$root.$el.querySelector('.app-content .error') as HTMLDivElement | undefined;
    error?.scrollIntoView({ behavior: 'smooth' });
  }

  get AdvisorFormViewType() {
    return AdvisorFormViewType;
  }

  get advisorPermissions() {
    return this.globalSelects?.brokerPermissions ? this.globalSelects.brokerPermissions : [];
  }

  get documentTypes() {
    return this.investorsSelects?.documentType ? this.investorsSelects.documentType.map((el: any) => { return { label: el.label, value: el.value } }) : [];
  }

  get countries() {
    return this.globalSelects?.countries ? this.globalSelects.countries : [];
  }

  get AdvisorRoles() {
    return AdvisorRoles;
  }

  get title(): string {
    switch (this.viewType) {
      case AdvisorFormViewType.editAdvisor:
        return 'Edit advisor';
      case AdvisorFormViewType.newAdvisor:
        return 'Add new advisor';
      default:
        return '';
    }
  }

  get redButtonText(): string {
    if(this.viewType === 'editAdvisor'){
      return 'Save';
    } else {
      return 'Create'
    }
  }

  setPermission(permission: any) {
    if((this.brokerData?.permissions?.includes(permission?.value))) {
      const permissionIndex = this.brokerData?.permissions?.indexOf(permission?.value);
      if(permissionIndex >= 0) {
        this.brokerData?.permissions?.splice(permissionIndex, 1);
      }
    } else {
      this.brokerData?.permissions?.push(permission?.value);
    }
  }

  hasPermission(permission: any) {
    return (this.brokerData?.permissions?.includes(permission?.value)) ? true : false;
  }

  async loadDictionaries() {
    await Promise.all([
      this.$store.dispatch('dictionary/getDocumentTypes'),
      this.$store.dispatch('dictionary/getBrokerPermissions'),
    ]);
  }

  async beforeMount() {
    await this.loadDictionaries();

    AdvisorRoles.forEach((role: AdvisorRole) => {
      this.roles[role.id] = false;
    });

    if(this.$route?.query?.id) {
      this.viewType = AdvisorFormViewType.editAdvisor;
    }

    try {
      switch (this.viewType) {
        case AdvisorFormViewType.editAdvisor: {
          let brokerData = await this.$store.dispatch('advisors/getBroker', this.$route.query.id);
  
          brokerData = {
            documentExpiryDate: brokerData.documentExpiryDate,
            documentCountry: brokerData.documentCountry,
            documentNumber: brokerData.documentNumber,
            documentType: brokerData.documentType,
            externalId: brokerData.externalId,
            phoneNumber: brokerData.phoneNumber,
            middleName: brokerData.middleName,
            email: brokerData.email,
            endPowerOfAttorney: brokerData.endPowerOfAttorney,
            firstName: brokerData.firstName,
            identificationNumber: brokerData.identificationNumber,
            permissions: brokerData.permissions,
            startPowerOfAttorney: brokerData.startPowerOfAttorney,
            surname: brokerData.surname,
          }
  
          this.brokerData = brokerData;
          break;
        }
      }
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
  }

  async goNext() {
    this.$v.$touch();
    if(!this.$v.$error) {
      this.isLoading = true;

      try {
        switch (this.viewType){
          case AdvisorFormViewType.editAdvisor:
            await this.$store.dispatch('advisors/editBroker', { brokerId: this.$route.query.id, data: this.brokerData })
            this.$notify({
              type: 'success',
              title: 'Success',
              text: 'Changes saved',
            })
            break;
          case AdvisorFormViewType.newAdvisor:
            await this.$store.dispatch('advisors/postBroker', this.brokerData);
            this.$notify({
              type: 'success',
              title: 'Success',
              text: 'Advisor created',
            })
            this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising`, query: { page: 'advisors' } });
            break;
          }
      } catch(e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        });
      }

      this.isLoading = false;

    } else {
      this.$nextTick(() => {
        const appContentDiv: HTMLDivElement = this.$root.$el.querySelector('.app-content') as HTMLDivElement | undefined;
        appContentDiv.querySelector('.error')?.scrollIntoView({ behavior: 'smooth' });
      })
    }
  }

  get validationObject() {
    const validation: any = {
      brokerData: {
        firstName: { required },
        surname: { required },
        email: { required, email },
      }
    }

    if (this.isAdvisorFormFieldRequired('identification_number')) {
      validation.brokerData.identificationNumber = { required }
    }

    if (this.isAdvisorFormFieldRequired('middle_name')) {
      validation.brokerData.middleName = { required }
    }

    if (this.isAdvisorFormFieldRequired('phone_number')) {
      validation.brokerData.phoneNumber = { required }
    }

    if (this.isAdvisorFormFieldRequired('external_id')) {
      validation.brokerData.externalId = { required }
    }

    if (this.isAdvisorFormFieldRequired('document_type')) {
      validation.brokerData.documentType = { required }
    }

    if (this.isAdvisorFormFieldRequired('document_number')) {
      validation.brokerData.documentNumber = { required }
    }

    if (this.isAdvisorFormFieldRequired('document_expiry_date')) {
      validation.brokerData.documentExpiryDate = { required }
    }

    if (this.isAdvisorFormFieldRequired('document_country')) {
      validation.brokerData.documentCountry = { required }
    }

    if (this.isAdvisorFormFieldRequired('start_power_of_attorney')) {
      validation.brokerData.startPowerOfAttorney = { required }
    }

    if (this.isAdvisorFormFieldRequired('end_power_of_attorney')) {
      validation.brokerData.endPowerOfAttorney = { required }
    }

    return validation;
  }

  validations() {
    return this.validationObject;
  }
}

